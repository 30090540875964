// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Popper from 'popper.js';
import { Util } from 'bootstrap';
import { Slick } from 'slick-carousel';


$(".js-main-slider").slick({
    arrows: false,
    dots: true
});

$(".js-card-promo-carousel").slick({
    arrows: true,
    autoplay: true,
    fade: true,
    dots: true
});

$('.js-carousel-recorrido').slick({
    mobileFirst: true,
    slidesToShow: 1,
    centerMode: true,
    prevArrow: '<button type="button" class="slick-prev"> < </button>',
    nextArrow: '<button type="button" class="slick-next"> > </button>',
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 2
        }
    }]
});

$('.js-carousel-experiencia').slick({
    mobileFirst: true,
    slidesToShow: 1,
    centerMode: true,
    prevArrow: '<button type="button" class="slick-prev"> < </button>',
    nextArrow: '<button type="button" class="slick-next"> > </button>',
    centerPadding: '20px',
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            centerMode: false
        }
    }]
});

$('.js-call-to-resort').slick({
    mobileFirst: true,
    slidesToShow: 3,
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: '20px',
    responsive: [{
        breakpoint: 576,
        settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: '100px',
        }
    },{
        breakpoint: 992,
        settings: {
            slidesToShow: 3,
            centerMode: false,
            centerPadding: '100px',
        }
    }]
});

if($(".videohome").length){
    $(".videohome")[0].play();
}